// prettier-ignore
module.exports = {
  colours: {
      'black': '#212121',
      'd-grey': '#4D4D4D',
      'grey': '#6D6E70',
      'l-grey': '#BBBDC0',
      'lighter-grey': '#DFE7EE',
      'm-grey': '#707070',
      'drop-grey': '#ABBED160',
      'silver': '#EFEFF0',
      'grey-blue': '#F1FDFF',
      'white': '#FFFFFF',
      'xl-lilac': '#F5F3FE',
      'm_violet': '#BEB4FF',
      'd-violet': '#6054B8',
      'brand-aa': '#277E8B',
      'brand': '#309fb0',
      'm-brand': '#61BFCE',
      'l-brand': '#8FD1DC',
      'azure': '#8FD1DC',
      'sky': '#E8F6F7',
      'd-blue': '#244968',
      'l-blue': '#EEF5FC',
      'sky-grey': '#90A4B4',
      'l-sky-grey': '#CBD6DE',
      'xl-sky-grey': '#F0F4F8',
      'd-green': '#115B43',
      'forest': '#1B6E53',
      'm-green': '#009262',
      'whatsapp-green': '#25D366',
      'herbal': '#32C997',
      'mint': '#84DFC1',
      'l-green': '#F1FBF8',
      'orange': '#FE7F2D',
      'm-orange': '#F59556',
      'xl-orange': "#FEDAC4",
      'd-yellow': '#E48900',
      'lemon': '#FFA826',
      'm-yellow': '#FFB240',
      'straw': '#FFC670',
      'sand': '#FFD596',
      'l-yellow': '#FFF8EC',
      'd-red': '#C33025',
      'scarlet': '#E84C4E',
      'm-red': '#EA6D6F',
      'raspberry': '#F19B9C',
      'rubicund': '#FED2D3',
      'l-red': '#FFF2F2',
      'transparent': 'transparent',
      'brand-grey': '#D9D9D9'
  }
};
