import "shared/extensions/date";

import { Route, Routes } from "react-router-dom";

import { AccountSecurityHome } from "./Views/AccountSecurityHome/AccountSecurityHome";
import { FeatureToggle } from "core/auth/FeatureToggle";
import { PasswordManagement } from "./Views/PasswordManagement/PasswordManagement";
import { SsoSettings } from "./Views/SsoSettings/SsoSettings";

export const AccountSecurity = () => {
  const showAccountSecurity = !!process.env.REACT_APP_SHOW_ACCOUNT_FEATURE_TOGGLE;

  return (
    <FeatureToggle showFeature={showAccountSecurity}>
      <Routes>
        <Route index element={<AccountSecurityHome />} />
        <Route path="sso" element={<SsoSettings />} />
        <Route path="password-management" element={<PasswordManagement />} />
      </Routes>
    </FeatureToggle>
  );
};
